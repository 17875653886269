import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    margin: "40px 0px",
  },
  title: {
    fontSize: "1.5rem !important",
    fontWeight: "500 !important",
    marginBottom: "20px !important",
    textAlign: "center",
  },

  content: {
    fontSize: "1rem",
    marginBottom: "20px !important",
  },
  subtitle: {
    fontSize: "1rem",
    marginBottom: "20px !important",
    fontWeight: "500 !important",
  },

  icon: {
    marginRight: 10,
    position: "relative",
    top: 5,
  },
  select: {
    marginBottom: 30,
  },
  footer: {
    marginTop: "-80px",
  },
  center: {
    textAlign: "center",
  },
}));

const PersonalTC = () => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        Terms and Conditions (Personal Account)
      </Typography>

      <p className={classes.center}>
        THESE TERMS APPLY TO YOU IF YOU ARE A USER OF ST PAY.
        <br />
        By clicking on the checkbox "Terms and Conditions" and after successfully completing the registration process of ST Pay for a Customer Account, you are stating that you have read, understood and agreed to be bound by these Terms and Conditions of Service ("T&Cs"), which are not subject to negotiation. If you do not agree to these terms and conditions, you may not download, install or use the Software.
      </p>

      <h4>1. Definitions:</h4>
      <p>
        The following words and expressions shall have the corresponding meanings wherever appropriate.
      </p>

      <p>
        a) “ST Pay” shall mean the ST Pay e-wallet application of Green & Red Technologies Limited available in iOS, android in smart phone or tab devices and web version. <br />
        b) “ST Pay's website” means https://www.stpay.com <br />
        c) “We” or “ST Pay” is referred to as Green & Red Technologies Limited.{" "}
        <br />
        d) “Company” means Green & Red Technologies Limited, the creator of
        ST Pay <br />
        e) “PSP license” shall mean the license for Payment Service Provider
        issued by the central Bank of Bangladesh. Green & Red Technologies Limited is a PSP license holder issued and authorized by Bangladesh
        Bank. <br />
        f) “e-Wallet” or digital wallet shall mean a mobile or online based app
        where user securely contain or maintain their physical money
        electronically. <br />
        g) “e-Wallet Holder” means the person or business/merchant who is the
        owner of ST Pay e-wallet. <br />
        h) “Device ID” means fingerprints or PIN or pattern generated by the
        device user, able to be used to identify the valid user. It can be used
        as the user identity to access the ST Pay e-wallet. <br />
        i) “Mobile Phone Number” shall mean the Mobile number that is used to
        register for the ST Pay e-wallet which will also be used as username to
        login to the e-wallet by the account holder. <br />
        j) “OTP” shall mean One Time Password, which is used to authorize a
        transaction/activity provided by mobile and web app. <br />
        k) “Oops” error message in the transaction means the message that
        appears if something goes wrong. <br />
        l) “Password” shall mean a string of characters that allows the users
        access to personal ST Pay wallet account. <br />
        m) “PIN” shall mean 4 digits Personal Identification Number (password)
        for the e-Wallet Service. <br />
        n) “Product” shall mean e-Wallet Service provided to the e-Wallet
        holder. <br />
        o) “Transaction” shall mean the mode of payment whether paid or received
        or transferred or withdrawn. <br />
        p) “QR Code” (quick response code) means a type of 2D bar code
        containing the credentials of merchants or individuals that is used to
        provide easy access to information through a smartphone <br />
      </p>

      <h4 >2. Applicability of Terms and Conditions</h4>
      <p>
        No one is entitled to use the ST Pay e-wallet without proper
        understanding hereby agreeing to the Terms and Conditions of the
        Service. By using the ST Pay e-wallet, you thereby agree and give consent
        to these Terms and Conditions, which form the contract agreement between
        you and ST Pay. ST Pay shall be governed by such existing terms and
        conditions and also by amendments made to the same from time to time.
        These terms and conditions shall be in addition to and not in derogation
        of other terms and conditions relating to any of your account and/or the
        respective product or the service provided by ST Pay unless otherwise
        specifically stated.
      </p>

      <h4 >3. General Business Rules</h4>

      <p>
        Issue of personal e-wallet is governed by ST Pay Policy. The following
        Business rules will apply to your personal account.
      </p>

      <p>
        a) The e-wallet is “non-transferable”. <br />
        b) No interest shall be payable on the balance available in e-wallet.{" "}
        <br />
        c) The product will be available to applicants having a valid Mobile
        number, photo identity proof (NID), more than 18 years of age, address
        by way of electricity bill form, and a bank account with a schedule bank
        or MFS account of Bangladesh.
        <br />
        d) You agree and understand that you can have only one personal account.
        You shall not make any attempt to create any further ST Pay account in
        excess of your one personal account with ST Pay.”.
        <br />
        e) The transaction limits are added as Annexure A.
        <br />
        f) Three consecutive failed login attempts temporarily block your
        account unless you reset your password. You can reset your password from
        ST Pay using the instructions and guidelines provided by.
        <br />
        g) Any change in the business rules or any of the processes will be
        notified on ST Pay’s website and this will be construed as sufficient
        notice to you and therefore the new rules shall be binding upon you
        without any further notifice. No further notice will be provided through
        any other media.
        <br />
        <br />
        h) ST Pay reserves the right to reject your request without explaining
        any reason.
        <br />
        i) ST Pay may deactivate any dormant account for zero transactions, if
        the same has not been accessed by you for more than 1 (one) year and
        will be called as dormant e-Wallet/Limited e-Wallet. If the e-Wallet has
        not been re-activated within next 1 (one) month of the dormancy period,
        the account will be deactivated. The balance remains in the account will
        stay with ST Pay in a separate account and you can reactivate
        suspended/dormant e-Wallet as per the procedure laid down for the same.
        <br />
        j) You can request for termination of the e-wallet as per the procedure
        laid down for the same.
        <br />
        k) You shall remain accountable for all the transactions, transactions
        disputes or fees outstanding on the e-Wallet made prior to confirmation
        of any such cancellation request to ST Pay.
        <br />
        l) It shall be ST Pay’s endeavor to give a reasonable notice for Limited
        or termination of the e-Wallet, but ST Pay may at its own discretion
        limit temporarily or terminate the e-wallet, either entirely or
        partially, anytime without giving prior notice to you.
        <br />
        m) The e-wallet may be dormant/limited for any maintenance or repair
        work for any breakdown in the Hardware/ Software related to ST Pay, any
        emergency or security reasons without prior notice and ST Pay shall not
        be liable if such an action has to be taken for such reasons.
        <br />
        n) ST Pay may also terminate or suspend or limit the services under the
        e-wallet without prior notice if you violate the terms and conditions
        laid down by ST Pay or for any other reason.
      </p>

      <h4>4. Usage of Product</h4>

      <p>By accepting the terms and conditions, you:</p>

      <p>
        a) You shall not perform any activity that may hamper the ST Pay app or
        any services provided by ST Pay. <br />
        b) You will comply with the necessary guidelines of Money Laundering
        Prevention Act, 2012 and Anti-Terrorism Act, 2009 and all other relevant
        laws of Bangladesh, Bangladesh Bank directives, regulations, guidelines
        and circulars as applicable. You further acknowledge that you will not
        misuse the services of ST Pay by conducting any of the prohibited
        activities, unlawful activities or transactions <br />
        c) Authorize ST Pay for all transactions/services undertaken by using
        Password, transaction PIN or fingerprints and OTPs.
        <br />
        d) You will use the ST Pay App only for the purpose for which it is
        intended to be used.
        <br />
        e) You will not try to harm the service provided by ST Pay.
        <br />
        f) Agree to use the services offered under the product using the PIN in
        accordance with the procedure as laid down by ST Pay from time to time,
        including the terms and conditions contained herein.
        <br />
        g) Agree to keep the PIN and OTPs confidential and will not disclose
        these to any other person or will not record them in a way that would
        compromise the confidentiality of the same or the security of the
        service.
        <br />
        h) Agree that you are aware and accept that ST Pay e-wallet will enable
        you to transact using PIN/OTPs within the limit prescribed by the
        Bangladesh Bank.
        <br />
        i) Agree that you will not disclose your PIN, OTP or Card PIN to anybody
        including any officers of Green & Red Technologies Limited (ST Pay).
        You shall remain liable for any transactions made by your account
        irrespective of whether the transaction was done by you or anybody else.
        <br />
        j) Agree that the transactions originated using the mobile phones or web
        app are non-retractable.
        <br />
        k) Understand and explicitly agree that ST Pay has the absolute right to
        revise the prescribed ceilings prescribed by Bangladesh Bank and charges
        from time to time which will be binding upon you.
        <br />
        l) All the transaction through ST Pay will be in BDT currency.
        <br />
        m) Agree to use the product properly and validly registered in your name
        and undertake to use the wallet only through mobile phone number or via
        online which has been used to register for the e-wallet.
        <br />
        n) Expressly authorize ST Pay to carry out all requests/ transactions
        purporting to have been received from your registered number and
        authenticated with PIN/OTPs. In case of payment facilities like add
        money, send money, mobile recharge, bill payment, purchase, transfer to
        bank etc., you shall be deemed to have expressly authorized ST Pay to
        make the payment when a request is received from your account/ e-wallet.
        <br />
        o) Accept that any valid transaction originating from your registered
        number shall be assumed to have been initiated by you and any
        transaction authorized by the PIN/OTPs is duly and legally authorized by
        you.
        <br />
        p) Agree to keep yourself updated with regard to any
        information/modification relating to the services offered which would be
        publicized on ST Pay’s websites or notification sent on the ST Pay app and
        would be responsible for taking note of/compliance of such information/
        modifications in making use of the Product.
        <br />
        q) You shall not use the ST Pay services for any illegal purposes and
        shall remain liable for any illegal activities done by using your ST Pay
        account.
        <br />
        r) It is your responsibility to check and ensure that you have
        downloaded and installed the correct version of this App in your device.
        stpay shall not be liable if you do not have a compatible device or
        if you have downloaded the fake version of the App in your device.
        <br />
        s) All the information provided by you is true and accurate.
      </p>

      <h4>5. Password Policy</h4>
      <p>
        {" "}
        a) Password must have the following characteristics: <br />
        • Maximum length cannot be more than thirty-two 32 characters <br />
        • Minimum length must be eight (8) characters <br />
        • At least one (1) number <br />
        • At least one (1) uppercase  letter. <br />
        • At least one unique/special character. <br />
        b) In case of 3 unsuccessful attempts with wrong password, your account
        access will be locked unless you reset your password. <br />
        c) Password Protection Standards <br />
        d) Passwords must not be inserted into email messages or other forms of
        electronic communication. <br />
        e) In case of changing the password, new passwords cannot be the same as
        the four previously used passwords. <br />
        f) Do not use previously used password for ST Pay account. Password is
        case sensitive and confidential ST Pay information. <br />
        Here is a list of "don’ts": <br />
        • Don't send your password through email <br />
        • Don't talk/type password in front of others <br />
        • Please at all the time keep your password confidential <br />• In an
        event that you feel your password may be compromised, please change the
        password or call our customer service to block your account. <br />•
        Don't reveal a password to anyone even to our Call center
      </p>

      <h4>6. Identity authentication (KYC)</h4>
      <p>
        a) It is mandatory that we follow strict guideline of Bangladesh Bank
        for KYC. Hence, you are authorizing ST Pay to make any inquiry we
        consider necessary to validate your identity. This may include asking you for further information, requiring you to provide your date of birth, mother’s name, father’s name, business address and other information that will allow us to reasonably identify you. We may ask to see your or all of your partners’ or directors’ NID. We may also ask for other business documents such as- trade license, memorandum, article of association, Certificate of Incorporation, Board Resolution, VAT certificate, TIN certificate, Proof of Address, NOC Letter (based on the nature of your business) at any time. ST Pay reserves the right to close, suspend, or limit access to your ST Pay account and/or the ST Pay services in the event we are unable to obtain or verify this information.
      </p>
      <h4>7. Account Usage</h4>
      <p>
        a) After providing the KYC information, even before approval of your
        account, you will be able to receive money to your ST Pay account. <br />
        b) After account verification and approval, you will be able to receive
        all the services provided by ST Pay. <br />
        c) ST Pay does not warrant that all functionalities shall be available at
        all times and may withdraw any prior notice any functionality or ST Pay
        Service in general as a direct result of new or amended legislation,
        statutory instrument, Government regulations or policy or any other
        compelling reason. <br />
        d) ST Pay may provide additional functionality on the ST Pay menu
        following integration with platforms of financial institutions and other
        entities providing you with specified services. Such functionality may
        give you limited access to platforms of the financial institutions or
        other entities. By using such functionality to gain access to other
        platforms, you hereby agree to indemnify ST Pay against, and hold ST Pay
        harmless from any losses arising from your access to such external
        platforms. <br />
        e) ST Pay reserves the right to determine all service fees and/or
        maintenance charges (including other applicable charges) for availing
        mobile financial services from time to. In addition, ST Pay reserves the
        right to determine any increase in its service fees and charges without
        any prior notice.
      </p>
      <h4>8. Link Bank</h4>
      <p>The transaction fees are added as Annexure B.</p>
      <p>
        a) ST Pay shall not be held liable if your link bank request is failed
        due to incorrect bank or KYC information or mismatch with that of the
        Bank Account In addition, ST Pay shall not be liable for any mishandling
        of transaction that occurs due to the fault of the bank or your device
        or internet issue. <br />
        b) To link your bank account with your ST Pay account, you are required
        to select your Bank from the bank list an insert your Bank Account
        information correctly. <br />
        c) During the process of link bank, you will be redirected to your bank’s Internet Banking page where you have to follow bank’s instruction or if your bank doesn’t have Internet banking an OTP will be sent to your bank registered mobile number for the purpose of authentication. You have to enter the OTP and the link will be established. <br />
        d) You shall abide by any further burden or obligation if the bank or any other government authority provides any such obligation upon you. <br />
        e) ST Pay reserves the right to add, remove and modify the existing terms and conditions for this service at any time as necessary without giving any prior notice. <br />
        f) By linking your bank account to ST Pay account, you hereby also authorize Green & Red Technologies Limited (ST Pay) to debit or credit your linked Bank Account through the settlement bank of ST Pay e-Wallet.{" "}
        <br />
        g) Once the link is established, you are able to add money from your linked bank account to your ST Pay account or you can transfer money from your ST Pay account to your linked bank account. <br />
        h) In case of non-delivery of OTP, pending Add Money or any other issue associated with your Bank account or service, you are required to contact your Bank.{" "}
        <br />
        i) In case of any issue associated with ST Pay account, you are requested to contact ST Pay Customer Care by sending e-mail to designated email address, by raising a ticket or by live chat from our application. <br />
      </p>
      <h4>9. Services Given by Third Party Networks</h4>
      <p>
        In case of any transaction through third party network, the mentioned
        party will solely be liable for the accuracy and authenticity of the
        information related to their services given by them. ST Pay is a payment
        service provider only and in no way liable for the accuracy and
        authenticity of their services. If you want to return a purchase or want
        to refund it will be followed by the third party’s prescribed terms and
        conditions. For Third Party service-related queries, please contact with
        the party or their authorized representatives in addition, ST Pay will
        not be liable for any promotional offers or deals offered by such third
        parties.
      </p>

      <h4>10. Transactions through ST Pay</h4>
      <p>
        a) Your Transaction request through ST Pay may not be completed: <br />
        i. If you have insufficient funds in your ST Pay account to complete a
        Transaction or to cover the charges for a transaction; <br />
        ii. If you have reached the maximum account balance limit prescribed by
        Bangladesh Bank; <br />
        iii. If you have reached the daily limit prescribed by Bangladesh Bank;{" "}
        <br />
        iv. If your Account has been temporarily suspended or permanently
        frozen; <br />
        v. If there is any other compelling reason such as temporary system
        delay or outage. <br />
        vi. If your transaction seems suspicious to ST Pay for any reason
        whatsoever; at the discretion of ST Pay. <br />
        b) In case of Add Money transaction, we will be able to add money from
        banks available in ST Pay list or any VISA/Master/UnionPay debit or
        credit card. <br />
        c) You are required to enter correct OTP received from your issuer bank
        through your bank registered mobile number for the purpose of Adding
        Money to your ST Pay account. <br />
        d) Green & Red Technologies Limited (G&R) shall not be liable for
        any Add Money being rejected by the system for the
        mistake/non-compliance of rules by you. <br />
        e) In case of transfer money to your bank account or credit card bill
        payment, you will be able to transfer money to any bank account or any
        VISA/Master/UnionPay debit or credit card. <br />
        f) It may take up to 2 working days to complete the transfer money
        process to a bank account or credit card bill payment. <br />
        g) Green & Red Technologies Limited (G&R) shall not be liable for
        any delay by your Bank to credit your Bank account through Transfer
        Money. <br />
        h) You are required to enter correct OTP or your transaction PIN of
        ST Pay (if required) to complete a transaction. <br />
        i) It is your responsibility to ensure that the status of the concerned
        Bank/card Account is active (is not frozen, dormant, or others) and
        transactions are allowed in the concerned Bank Account. ST Pay shall not
        be liable for any unsuccessful transaction due to the status of the
        Bank/card account and neither does ST Pay have any responsibility to
        communicate with the bank to secure any transaction. <br />
        j) ST Pay may cancel or reverse any transaction or debit your ST Pay
        account to correct any mistake, overpayment, or for any other reasonable
        grounds after proper investigation. If such cancelation, reversal, or
        debit is made by ST Pay, you will be duly notified. <br />
        k) You are bound to bear the fees/charges (if any) imposed by ST Pay
        which may vary from time to time for the services rendered. <br />
        l) You must ensure confidentiality, custody, and protection of the PIN
        of your ST Pay Account and the OTP provided by bank and/or ST Pay. ST Pay
        shall not be held accountable for any consequences in case PIN, OTP or
        the ST Pay account is compromised by you. <br />
        m) In case of non-delivery of OTP, pending Add Money or any other issue
        associated with your Bank account or service, you are required to
        contact your Bank. <br />
        n) You must ensure confidentiality, custody, and protection of the PIN
        of your ST Pay Account and the OTP provided by bank and/or ST Pay. ST Pay
        shall not be held accountable for any consequences in case PIN, OTP or
        the ST Pay account is compromised by you. <br />
        o) A Transaction once completed is final and irrevocable. As such, ST Pay
        shall not be under any compulsion to reverse any transaction. Therefore,
        you should ensure that the correct bank/card/ST Pay account details are
        entered while performing the transaction. <br />
        p) ST Pay will not be compelled to refund or compensate the sender if the
        fund is sent to a recipient mistakenly or in error or fraudulently or
        under duress or otherwise spent by the recipient of the mistaken,
        erroneous or fraudulent transaction and you hereby agree to hold ST Pay
        harmless for any losses arising from a mistaken, erroneous or fraudulent
        transfer of funds or any transfer of funds from your account effected
        under duress or under coercion or criminal force. <br />
        q) You may withdraw your money at participating ATM network Outlets at
        any time by accessing your ST Pay Account by generating an Bank Transfer
        token. It will be a one-time token which should be cashed at
        participating ATM networks within the prescribed timeframe. Failure to
        do so will cause the token to expire and fund will be reversed to your
        wallet balance. <br />
        r) You are required to contact your Bank, in case of any issue
        associated with your Bank account or service. <br />
        s) In case of any issue associated with ST Pay account, you are requested
        to contact ST Pay Customer Care Center by calling 000000, by sending
        e-mail to support@stpay.net, by raising a ticket or by live chat from
        our application.
      </p>

      <h4>11. Others</h4>

      <p>
        {" "}
        a) Your instructions will be affected only after authentication under
        your Registered number and PIN or through any other mode of verification
        as may be stipulated at the discretion of ST Pay. <br />
        b) ST Pay shall make all reasonable efforts to ensure that your
        information is kept confidential. <br />
        c) You will be required to acquaint yourself with the process for using
        the Product and that you shall be responsible for any error made while
        using the Product. <br />
        d) ST Pay reserves the right to decide what services may be offered.
        Additions/ deletions to the services offered under the product are at
        its sole discretion. <br />
        e) While it shall be the endeavor of ST Pay to carry out the instructions
        received from you promptly, it shall not be responsible for the delay/
        failure in carrying out the instructions due to any reasons whatsoever
        including failure of operational system or due to any requirement of
        law. You expressly authorize ST Pay to access your e-wallet information
        required for offering features under the service and also to share the
        information regarding your e-Wallet with the service provider/ third
        party as may be required to provide the services. <br />
        f) The transactional details will be recorded by ST Pay and these records
        will be regarded as conclusive proof of the authenticity and accuracy of
        transactions. <br />
        g) You hereby authorize ST Pay or its representatives to send promotional
        messages including the products, greetings, advertising or any other
        messages ST Pay may consider from time to time. <br />
        h) You understand that ST Pay may send "rejection" or "cannot process the
        request" messages for the service requested by you which could not be
        executed for any reason. <br />
        i) ST Pay reserves the right to replace or amend its features, facilities
        and charges, and the information and materials pertaining to such
        features, facilities and charges, in relation to the services provided
        by ST Pay, its contents, website, or any of its products
      </p>

      <h4>12. Fee structure for the Product</h4>
      <p>
        {" "}
        a) We will be entitled to set off and/or deduct from any payment due to
        you, such payments that are subsequently determined to be not due and/or
        wrongly paid to you. <br />
        b) All charges applicable to the ST Pay service are set forth in a
        separate fee schedule available at the website of ST Pay
        (www.stpay.com) and as may be published from time to time by Green & Red Technologies Limited (G&R) and are subject to change at any time at G&R’s sole discretion. <br />
        c) Any Fees and other amounts (such as- VAT etc.) due and payable to
        ST Pay under the Agreement may be deducted from your e-wallet with or
        without notice. Such deductions may be made at any time, and without
        notifying you, but these will be reflected in the transaction history of
        your e-wallet.
      </p>

      <h4>13. User Information</h4>

      <p>
        a) You agree that you shall defend, indemnify and hold ST Pay harmless
        from and against any and all claims, costs, damage, losses, liabilities
        and expenses arising out of or in connection with your violation or
        breach of any of these terms and conditions or any applicable law or
        regulation, or your use or misuse of the services of ST Pay. <br />
        b) You shall be liable for any claim for damages, penalty, losses, legal
        suit, or criminal offence if such an occurrence arises due to any
        unauthorized use of the services of ST Pay. <br />
        c) It is your responsibility to provide correct information to ST Pay
        through the use of the Product or any other method. In case of any
        discrepancy in this information, you understand that ST Pay will not be
        in any way responsible for action taken based on the information. ST Pay
        will endeavor to correct the error promptly wherever possible on a best
        effort basis, if you report such error within 2 (two) hours of providing
        the error in information. <br />
        d) If you receive information about another ST Pay customer, you must
        keep the information confidential and only use it in connection with the
        ST Pay services. You may not disclose or distribute any information about
        ST Pay users to a third party or use the information for marketing
        purposes unless you receive that user’s express consent to do so. You
        may not send unsolicited emails to a ST Pay customer or use the ST Pay
        services to collect payments for sending, or assist in sending,
        unsolicited emails to third parties. <br />
        e) We are not responsible for any loss or damage you suffered as a
        result of you providing wrong information. <br />
        f) In case of providing inaccurate or incorrect information to us, we
        may immediately limit or suspend or close your account and refuse to
        provide our Services to you. g) In case of any authorized regulatory
        body’s involvement or order, we are bound to provide information to them
        for any kind of investigation or may even freeze your account until such
        investigation is resolved. <br />
        h) ST Pay reserve the right to refuse any service to any ST Pay user
        without giving any reason or prior warning.
      </p>

      <h4>14. Responsibilities and obligations of the E-Wallet holder</h4>
      <p>
        a) It will be your responsibility to notify ST Pay immediately if you
        suspect the miss use of the PIN/Password. <br />
        b) You will be responsible for all transactions, including
        erroneous/mistakenly initiated through ST Pay, mobile number and PIN;
        regardless of whether such transactions are authorized by you. You will
        also be responsible for the loss/damage, if any suffered in respect of
        all such transactions. <br />
        c) You will take all possible steps to ensure that the PIN, Password and
        OTPs are not shared with any unauthorized person and shall contact ST Pay
        customer care center in case of misuse/ theft/loss of the mobile phone.{" "}
        <br />
        d) You will be liable for all loss or breach of the terms and conditions
        contained herein or contributed or caused the loss by negligent actions
        or a failure to advise ST Pay within a reasonable time about any
        unauthorized access in the Wallet.
      </p>

      <h4>15. Refund and Chargeback</h4>
      <p>
        a) We will not be liable to you for any refund/Chargeback not initiated
        through ST Pay. <br />
        b) If we receive a request for Refund/chargeback of payment, the dispute
        resolution process will be followed and it will vary case to case.{" "}
        <br />
        c) If you want to claim a chargeback, you have to raise a ticket through
        the application mentioning the reason. These reasons offer an
        explanation as to why you are disputing the transaction. Each reason has
        its own set of rules (filing time limits, necessary documentation,
        etc.). ST Pay will check the customer’s chargeback claim, making sure all
        the regulations have been addressed. <br />
        d) If you have a valid claim according to ST Pay, the funds will be
        removed from the merchant’s wallet account and credited to yours. If it
        is not a valid claim according to ST Pay, the chargeback will be voided.{" "}
        <br />
        e) However, if the merchant has sufficient compelling evidence
        (documentation to prove the chargeback is invalid), the merchant can
        re-present the chargeback for review. If a chargeback process is
        successful, it will auto reconcile your account, ST Pay and Merchant.
        Merchant will be liable to pay the fees associated with disputed
        transaction. <br />
        f) If you want to return a purchase or want to refund for non-delivery
        items it will be followed by the merchant’s prescribed terms and
        conditions. If the merchant finds the request valid as per his/her
        business policy, he will initiate the refund through the application.
        Merchant will be liable to pay the fees associated with transaction
        amount. <br />
        g) In case of Refund/Chargeback, the money will be refunded to the
        original payment method you used for the transaction such as- a debit
        card, credit card, or ST Pay account.
      </p>

      <h4>16. Disclaimer</h4>
      <p>
        a) ST Pay will not be liable for any loss caused by a technical breakdown
        of the payment system. <br />
        b) Use of the e-wallet will be terminated without notice at ST Pay’s
        discretion which may be upon the death, bankruptcy or insolvency of the
        E-Wallet holder or on receipt of request from the e-Wallet holder,
        receipt of an attachment order from a competent court or revenue
        authority, due to violation of ST Pay regulations, or for other valid
        reasons or when the whereabouts of the e-Wallet holder become unknown to
        ST Pay due to any cause attributable to the e-Wallet holder or any other
        reason which ST Pay deems fit. <br />
        c) ST Pay will not be responsible if the e-wallet application is not
        compatible with/ does not work on your mobile handset. <br />
        d) ST Pay, when acting in good faith, will be absolved of any liability
        in case: <br />
        • ST Pay is unable to receive or execute any of the requests from you
        when there is loss of information during processing or transmission or
        any unauthorized access by any other person or breach of confidentiality
        or due to reasons beyond the control of the ST Pay. <br />
        • There is any kind of loss, direct or indirect, incurred by you or any
        other person due to any failure or lapse in the product which are beyond
        the control of the ST Pay. <br />
        • There is any failure or delay in transmitting of information or there
        is any error or inaccuracy of information or any other consequence
        arising from any cause beyond the control of ST Pay which may include
        technology failure, mechanical breakdown, power disruption, etc. <br />
        • There is any lapse or failure on the part of the service providers or
        any third party affecting the said Product and that ST Pay makes no
        warranty as to the quality of the service provided by any such provider
        for any delay or non-performance of its obligations under these terms
        and conditions arising from any cause or causes beyond its reasonable
        control, including (but not limited to) an act of God, governmental act,
        earthquake, war, fire, flood, explosion, civil commotion or industrial
        dispute whether lawful or otherwise, armed hostilities, act of
        terrorism, suicide bombing and attacks, explosions; revolution;
        blockade; embargo; strike; lock-out sit-in industrial or trade dispute;
        adverse weather; epidemic or pandemic; industrial accident and natural
        disasters. <br />
        e) ST Pay and its employees will not be liable for and in respect of any
        loss or damage whether direct, indirect or consequential, including but
        not limited to loss of revenue, profit, business, contracts, anticipated
        savings or goodwill, loss of use or value of any equipment including
        software, whether foreseeable or not, suffered by you or any person
        howsoever arising from or relating to any delay, interruption,
        suspension, resolution or error of ST Pay in receiving and processing the
        request and in formulating and returning responses or any failure,
        delay, interruption, suspension, restriction, or error in transmission
        of any information or message to and from the telecommunication
        equipment and the network of any service provider and ST Pay’s system or
        any breakdown, interruption, suspension or failure of the
        telecommunication equipment, ST Pay’s system or the network of any
        service provider and/or any third party who provides such services as is
        necessary to provide the Product.
      </p>
      <h4>17. Prohibited actions and Transactions</h4>
      <p>The following transactions or actions are prohibited:</p>
      <p>
        I. To use your e-wallet for purposes including, but not limited to,
        Money Laundering, fraud, terrorist financing or such other
        illegal/criminal activities. <br />
        II. Report the transaction to the relevant law enforcement agency <br />
        III. To use your e-Wallet to receive funds from persons or entities
        engaged in fraud, terrorist financing, money laundering, fraud or other
        illegal/criminal activities. <br />
        IV. Refuse to cooperate in an investigation or provide confirmation of
        your identity or any other information provide by you to ST Pay; <br />
        V. Provide false, inaccurate or misleading information; <br />
        VI. If you act in breach or we believe that you may be acting in breach
        of the prohibitions contained in Clauses 16 (d) and 16(e) we reserve the
        right to: <br />
        VII. Decline to perform or reverse the transaction and simultaneously
        limit or terminate or suspend your e-Wallet or forfeit your account;{" "}
        <br />
        VIII. It is strictly your responsibility to ensure that you only send or
        receive e-money from or to Persons or entities for the sale or supply of
        goods and services that you may provide or receive in compliance with
        any applicable laws and regulations. Funds that have been transferred to
        another Persons' or entity’s account does not indicate the legality of
        the supply or provision of their goods or services. It is required that
        you should not proceed with any transactions in the instance where you
        have any hints that the transaction is not legal.
      </p>
      <h4>18. Security as Per PCI DSS</h4>
      <p>
        ST Pay has established security procedures and shall make reasonable
        efforts consistent with industry standards to protect cardholder data,
        meet all applicable audit requirements and comply with PCI DSS
        (hereinafter “Payment Card Industry Data Security Standards”) and such
        other applicable rules, regulations, codes of practice, guidance and
        industry standards related to the handling and processing of credit card
        data in force from time to time during the term hereof “Payment Card
        Issuer Requirements”, as established by the PCI Security Standards
        Council. ST Pay is responsible for the security of cardholder data ST Pay
        possesses or otherwise stores, processes, or transmits on Customer’s
        behalf, or to the extent ST Pay could impact the security of Customer’s
        cardholder data environment. ST Pay agrees to comply with all applicable
        PCI DSS requirements to the extent that ST Pay handles, has access to, or
        otherwise stores, processes or transmits Customer’s cardholder data, or
        manages Customer’s cardholder data environment. ST Pay agrees to comply
        with all applicable laws that require notification of individuals or
        parties in the event of unauthorized disclosure of cardholder data.
        Pursuant to the provisions hereof, in the event of a breach of any of
        ST Pay’s security obligations relating to PCI or other event requiring
        notification under applicable law, ST Pay agrees to assume responsibility
        for informing all such individuals in accordance with applicable laws,
        and, subject to the indemnity and limitations on liability provisions
        contained herein.
      </p>
      <h4>19. Governing Law & Jurisdictions</h4>
      <p>
        The Product and the terms and conditions of the same are governed by the
        applicable laws in the Peoples’ Republic of Bangladesh such as-
        Bangladesh Bank regulations under PSP license, Payment and settlement
        act, 2014 or any other regulatory bodies.
      </p>

      <h4>20. Indemnity</h4>
      <p>
        In consideration of ST Pay providing the Product, you agree to indemnify
        and hold ST Pay harmless against all actions, claims, demands
        proceedings, loss, damages, costs, charges and expenses which ST Pay may
        at any time incur, sustain, suffer or be put to as a consequence of or
        arising out of or in connection with any services provided to you
        pursuant hereto. You shall indemnify and keep indemnified ST Pay for
        unauthorized access by any third party to any
        information/instructions/triggers given by the you or breach of
        confidentiality.
      </p>

      <h4>21. Modification of this Terms and Conditions</h4>
      <p>The following transactions or actions are prohibited:</p>
      <p>
        a) Please ensure that you read the revised terms and conditions
        carefully because you will be bound by the revised terms and conditions
        from this date. <br />
        b) Any notice or notification in connection with these terms and
        conditions will be communicated through your registered email or via
        ST Pay Business App or via ST Pay official website. <br />
        c) ST Pay reserves the right to change, modify, add, or remove portions
        of this terms and conditions (each, a change and collectively, changes)
        at any time by posting notification on the ST Pay Platform or otherwise
        communicating the notification to you. The changes will become
        effective, and shall be deemed accepted by you, 24 hours after the
        initial posting and shall apply immediately on a going-forward basis
        with respect to your use of the ST Pay Platform, availing the ST Pay
        Services or for payment transactions initiated after the posting date.
        If you do not agree with any such change, your sole and exclusive remedy
        is to terminate your use of the ST Pay Services/ST Pay Platform. For
        certain changes, ST Pay may be required under applicable law to give you
        advance notice, and ST Pay will comply with such requirements. Your
        continued use of the ST Pay Platform following the posting of changes
        will mean that you accept and agree to the changes.
      </p>

      <h4>22. Severability</h4>
      <p>
        If any provision of this terms and condition is held to be unlawful,
        void, invalid or otherwise unenforceable, then that provision will be
        limited or eliminated from this terms and conditions to the minimum
        extent required, and the remaining provisions will remain valid and
        enforceable.
      </p>
      <h4>Annexure A</h4>
      <table className="myTable">
        <tbody>
          <tr>
            <th colSpan="3" className={classes.center}>
              Transaction Limit (Personal Wallet)
            </th>
          </tr>
          <tr>
            <td>Sl. No</td>
            <td>Description</td>
            <td>Amount (BDT)</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Maximum balance at any point of time</td>
            <td>4,00,000</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Add Money through any channel per transaction</td>
            <td>50,000</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Add Money through any channel per day</td>
            <td>1,00,000</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Add Money Per Month</td>
            <td>4,00,000</td>
          </tr>

{/*           <tr>
            <td>5</td>
            <td>Purchase (Personal to Business)</td>
            <td>No Limit</td>
          </tr> */}

          <tr>
            <th>5</th>
            <th colSpan="2">Payment (EComm / Make Payment)</th>
          </tr>
          <tr>
            <td></td>
            <td> -Per transaction limit</td>
            <td>1,00,000</td>
          </tr>
          <tr>
            <td></td>
            <td> -Daily limit</td>
            <td>1,00,000</td>
          </tr>
          <tr>
            <td></td>
            <td> -Monthly limit</td>
            <td>4,00,000</td>
          </tr>

          <tr>
            <th>6</th>
            <th colSpan="2">Send Money</th>
          </tr>
          <tr>
            <td></td>
            <td> -Per transaction limit</td>
            <td>50,000</td>
          </tr>
          <tr>
            <td></td>
            <td> -Daily limit</td>
            <td>1,00,000</td>
          </tr>
          <tr>
            <td></td>
            <td> -Monthly limit</td>
            <td>4,00,000</td>
          </tr>
          <tr>
            <th>7</th>
            <th colSpan="2">Transfer to Bank A/C *</th>
          </tr>
          <tr>
            <td></td>
            <td> -Per transaction limit</td>
            <td>50,000</td>
          </tr>
          <tr>
            <td></td>
            <td> -Daily limit</td>
            <td>1,00,000</td>
          </tr>
          <tr>
            <td></td>
            <td> -Monthly limit</td>
            <td>4,00,000</td>
          </tr>
        </tbody>
      </table>
      <p>
        Note: 
        *ST Pay reserves the right to change the limit structure at its discretion. <br/>
        *ST Pay user will receive the amount withing 1-3 business days.
      </p>
      <h4>Annexure B</h4>
      <table className="myTable">
        <tbody>
          <tr>
            <th colSpan="3" className={classes.center}>
              Transaction Fee (Personal Wallet)
            </th>
          </tr>
          <tr>
            <td style={{ fontWeight: 600 }}>Service</td>
            <td className={classes.center}>Fee/Charge (Including VAT)</td>
          </tr>
          <tr>
            <td>Registration</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Account Maintenance</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Unlink Bank</td>
            <td>BDT _</td>
          </tr>
          <tr>
            <td>Utility Bill Payment</td>
            <td>BDT __</td>
          </tr>
          <tr>
            <td>Credit Card Bill Payment</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>Add Money from Debit/Credit Card</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Add Money from Bank</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Transfer Money to Bank</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>Send Money (Wallet to Wallet)</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Receive Money (Wallet to Wallet)</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Mobile Recharge</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>QR Code Payment</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Transaction History</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Bank Transfer (minimum withdrawal amount BDT 100)</td>
            <td>1%</td>
          </tr>
        </tbody>
      </table>
      <p>
        * ST Pay reserves the right to change the fee structure at its
        discretion.
      </p>

      <br/>
      <h4>23. Delete Account</h4>
      <p style={{marginTop: "-10px"}}>
      To close the ST Pay Account, visit the ST Pay Corporate Office in-person along with your NID. Upon your visit, our team will assist you with the account closure process. Once your account is successfully closed, your data will be managed in accordance with the guidelines of Bangladesh Bank and relevant laws of Bangladesh. This includes both the retention and deletion of your data, ensuring compliance with all regulatory requirements.
      </p>

      <style>{`
        .myTable:nth-of-type(1) > tbody > tr > td:nth-of-type(1), 
        .myTable:nth-of-type(1) > tbody > tr > td:last-of-type, 
        .myTable:nth-of-type(1) > tbody > tr > th:nth-of-type(1) {
          text-align: center;
          font-weight: 600;
        }


        .myTable:nth-of-type(2) > tbody > tr > td:last-of-type {
          text-align: center;
        }

      `}</style>


    </div>
  );
};

export default PersonalTC;

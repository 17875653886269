import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    margin: "40px 0px",
  },
  title: {
    fontSize: "1.5rem !important",
    fontWeight: "500 !important",
    marginBottom: "20px !important",
    textAlign: "center",
  },
  content: {
    fontSize: "1rem",
    marginBottom: "20px !important",
  },
  subtitle: {
    fontSize: "1rem",
    marginBottom: "20px !important",
    fontWeight: "500 !important",
  },

  icon: {
    marginRight: 10,
    position: "relative",
    top: 5,
  },
  select: {
    marginBottom: 30,
  },
  footer: {
    marginTop: "-80px",
  },
  center: {
    textAlign: "center",
  },
}));

const MerchantTC = () => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        Account Holder Rules (Merchant account)
      </Typography>

      <p className={classes.center}>
        THESE TERMS APPLY TO YOU IF YOU ARE A MERCHANT OF ST PAY.
        <br />
        By clicking on the checkbox "Terms and Conditions" and successfully
        completing the registration process for a Merchant account, you are
        stating that you are eligible to be a Merchant with us and that you have
        read, understood and agreed to be bound by all of these Terms and
        Conditions of Service ("T&Cs"), which are not subject to negotiation. If
        you do not agree to these terms and conditions, you may not download,
        install or use the App.
      </p>

      <h4>1. Definitions:</h4>

      <p>
        a) “ST Pay” shall mean the ST Pay e-wallet application available in iOS,
        android in smart phone or tab devices and web version. <br />
        b) “ST Pay's website” means www.stpay.com. <br />
        c) “Company” means Green & Red Technologies Limited, the creator of
        ST Pay. <br />
        d) “We” is referred to as ST Pay. <br />
        e) "User" refers to a registered account holder of a ST Pay Wallet.{" "}
        <br />
        f) “Password” shall mean a string of characters that allows the users
        access to merchant/business ST Pay wallet account. <br />
        g) “PIN” shall mean 4 digits Personal Identification Number for the
        e-wallet Service. <br />
        h) “Device ID” means fingerprints or PIN or pattern generated by the
        device user, able to be used to identify the valid user. It can be used
        as the user identity to access the ST Pay e-wallet. <br />
        i) “e-wallet” or digital wallet shall mean a mobile or online based app
        where user securely contain or maintain their physical money
        electronically. <br />
        j) “e-wallet Holder/account holder” means the person or the
        business/merchant who is the owner of ST Pay e-wallet. <br />
        k) "Merchant" means any of the establishments which accept payment
        through ST Pay Wallet for sale of their goods or services. <br />
        l) "Merchant account" refers to the account created for a Merchant
        Applicant as the case may be as soon as it successfully completes the
        process of registration via web at www.stpay.com <br />
        m) “Mobile Phone Number” shall mean the Mobile number that is used to
        register for the ST Pay e-wallet which will also be used as username to
        login to the e-wallet by the account holder. <br />
        n) “OTP” shall mean One Time Password, which is used to authorize a
        transaction/activity provided by mobile or web app. <br />
        o) “Product” shall mean e-wallet Service provided to the e-wallet
        holder. <br />
        p) “QR Code” (quick response code) means a type of 2D bar code
        containing the credentials of merchants or individuals that is used to
        provide easy access to information through a smart phone. <br />
        q) “Transaction” shall mean the mode of payment whether paid or received
        or transferred or withdrawn. <br />
        r) “PSP license” shall mean the license for Payment Service Provider
        issued by the central Bank of Bangladesh. Green & Red Technologies
        Limited is a PSP license holder issued and authorized by Bangladesh
        Bank.
      </p>

      <h4>2. Applicability of Terms and Conditions</h4>
      <p>
        No one is entitled to use the ST Pay e-wallet without proper
        understanding and agreeing to the Terms and Conditions of the Service.
        By using the ST Pay merchant application, you thereby agree and give
        consent to these Terms and Conditions, which form the contract between
        you and ST Pay. ST Pay shall be governed by such existing terms and
        conditions and also by amendments made the same from time to time. These
        terms and conditions shall be in addition to and not in derogation of
        other terms and conditions relating to any account of the merchant
        and/or the respective product or the service provided by ST Pay unless
        otherwise specifically stated. In case of any conflict between these
        terms and conditions and further amendments made by ST Pay, the further
        amendments shall take precedence.
      </p>

      <h4>3. Eligibility</h4>

      <p>
        a) You will be "Eligible" to be a Merchant account holder of ST Pay, if:{" "}
        <br />
        ◦ You are an entity registered in Bangladesh and all your licenses and
        certificates are up to date and renewed appropriately. <br />
        ◦ You have a corporate account with a schedule bank or MFS provider in
        Bangladesh. <br />◦ You do not provide any of the goods or services
        listed, which are banned under applicable laws and may be updated from
        time to time. <br />
        ◦ You operate your business within Bangladesh. <br />
        ◦ You receive payments only in BDT currency. and <br />
        ◦ You or your authorized representative authorized by company or
        partnership has attained more than 18 (eighteen) years of age and is
        competent to enter into a contract. <br />
        b) You may apply or continue to be a Merchant account holder only if you
        are Eligible. If you are not Eligible, please immediately abandon any
        and all attempts to register with us. <br />
        c) We reserve the right to terminate your Merchant account at any time
        for any reason whatsoever. <br />
        d) We rely completely on your representation that you are Eligible and
        will bear no responsibility if you or anyone who uses your Account or
        ST Pay Wallet is found to not be Eligible. <br />
        e) For micro merchant please check section 11 <br />
        f) ST Pay may require the following information and/or documents from
        you- <br />
        I. Name; <br />
        II. Father’s name; <br />
        III. Mother’s name; <br />
        IV. Address; <br />
        V. National Identification Document (NID); <br />
        VI. Passport; <br />
        VII. Photo; <br />
        VIII. Mobile Number; <br />
        IX. Driving License; <br />
        X. Tax Identification Number (TIN); <br />
        XI. Bank account details; <br />
        XII. Details of Credit card/Debit Card/Bank
        card/Visa/Mastercard/American Express; <br />
        XIII. E-mail Address; <br />
        XIV. Business or entity name; <br />
        XV. Registration and/or incorporation number of the business; <br />
        XVI. Trade License of the Business; <br />
        XVII. Details about any persons in the business including proprietor,
        shareholder, director, partner, or any other persons as may be required;{" "}
        <br />
        XVIII. Details about any licenses or permits of the business; <br />
        XIX. Address of the business; and/or <br />
        Any other information and/or documents as may be required by ST Pay
      </p>

      <h4>4. General Business Rules</h4>

      <p>
        Issue of merchant e-wallet is governed by the ST Pay Policy. The
        following Business rules will apply to your merchant account.
      </p>

      <p>
        a) The e-wallet account is “non-transferable”. <br />
        b) No interest shall be payable on the balance available in e-wallet.{" "}
        <br />
        c) While registering into ST Pay merchant application, the data provided
        by you must be true to the best of your knowledge, information and
        belief. We will bear no liability for false or incorrect registration
        data provided by you. Please ensure that your registered e-mail ID and
        registered phone number are in working order in order to receive
        communication from us. <br />
        d) You agree and understand that you can have only one merchant account
        with ST Pay with a single mobile number. <br />
        e) You hereby expressly consent to receive communications from us
        through your Registered e-mail ID and/or Registered Phone Number. <br />
        f) You agree to undertake due diligence and update yourself on ST Pay
        Regulations and other applicable laws that may have implications on your
        liability as a Merchant account holder; <br />
        g) Upon execution of the contract, you will become a Merchant account
        holder and will be entitled to Services subject to these terms and
        conditions. ST Pay customers will be entitled to purchase your goods
        and/or services using their ST Pay wallet. You shall remain responsible
        to the ST Pay customers for any claims by them regarding the goods or
        services provided by you. <br />
        h) Three consecutive failed login attempts will block your account for
        next three hours unless you contact us and verify your details. After
        the verification, you have to reset/ generate a new Password. <br />
        i) Any change in the business rules or any of the processes will be
        notified via registered email address or via ST Pay application or via
        ST Pay’s official website and this will be construed as sufficient notice
        to the e-wallet holder. No further notice will be provided through any
        other media. <br />
        j) ST Pay reserves the right to reject your request without explaining
        any reason. <br />
        k) ST Pay may deactivate your account for transactions, if the same has
        not been accessed for more than 1 (one) year and will be called as
        dormant account /Limited account. If the account has not been
        reactivated within the next 1 (one) month of the dormancy period, the
        balance will be forfeited and the e-wallet account will be deactivated.
        You can reactivate suspended/dormant accounts as per the procedure laid
        down for the same. <br />
        l) You can request for termination of the e-wallet as per the procedure
        laid down for the same. <br />
        m) You will remain accountable for all the transactions from your
        e-wallet account made prior to confirmation of any such cancellation
        request to ST Pay. <br />
        n) It shall be ST Pay's endeavor to give a reasonable notice for Limit or
        termination of the e-wallet account, but ST Pay may at its discretion
        limit temporarily or terminate the e-wallet, either wholly or partially,
        anytime without giving prior notice to you. <br />
        o) The e-wallet account may be dormant/limited for any maintenance or
        repair work for any breakdown in the Hardware/ Software related to
        ST Pay, any emergency or security reasons without prior notice and ST Pay
        shall not be liable if such an action has to be taken for such reasons.{" "}
        <br />
        p) ST Pay may also terminate or suspend or limit the services under the
        e-wallet without prior notice if you violate the terms and conditions
        laid down by ST Pay or for any other reason.”. <br />
        q) The transaction limits are added as Annexure A.
      </p>

      <h4>5. Password Policy</h4>
      <p>
        a) Password must have the following characteristics: <br />
        • Maximum length cannot be more than thirty-two 32 characters <br />
        • Minimum length must be eight (8) characters <br />
        • At least one (1) digit <br />
        • At least one (1) uppercase letter. <br />
        • At least one unique/ special character. <br />
        b) In case of 3 unsuccessful attempts with the wrong password, your
        account access will be disabled. In case disabled, please contact our
        customer care for assistance. <br />
        c) Passwords must not be inserted into email messages or other forms of
        electronic communication. <br />
        d) In case of changing the password, new passwords cannot be the same as
        the four previously used passwords. <br />
        e) Password Protection Standards <br />
        Do not use previously used passwords for ST Pay accounts. Password is
        case sensitive and confidential ST Pay information. Here is a list of
        "don’ts": <br />
        • Don't send your password through email. <br />
        • Don't talk/type passwords in front of others. <br />
        • Please at all times keep your password confidential. <br />• In an
        event that you feel your password may be compromised, please change the
        password or call our customer service to block your account. <br />•
        Don't reveal a password to anyone even to our Call center.
      </p>

      <h4>6. Identity authentication (KYC)</h4>

      <p>
        a)	It is mandatory that we follow strict guideline of Bangladesh Bank for KYC. Hence, you are authorizing ST Pay to make any inquiry we consider necessary to validate your identity. This may include asking you the following documents/information-
      </p>
      <p>
        I. Name; <br />
        II. Father’s name; <br />
        III. Mother’s name; <br />
        IV. Address; <br />
        V. National Identification Document (NID); <br />
        VI. Passport; <br />
        VII. Photo; <br />
        VIII. Mobile Number; <br />
        IX. Driving License; <br />
        X. Tax Identification Number (TIN); <br />
        XI. Bank account details; <br />
        XII. Details of Credit card/Debit Card/Bank
        card/Visa/Mastercard/American Express; <br />
        XIII. E-mail Address; <br />
        XIV. Business or entity name; <br />
        XV. Registration and/or incorporation number of the business; <br />
        XVI. Trade License of the Business; <br />
        XVII. Details about any persons in the business including proprietor,
        shareholder, director, partner, or any other persons as may be required;{" "}
        <br />
        XVIII. Details about any licenses or permits of the business; <br />
        XIX. Address of the business; and/or <br />
        XX. Any further documents and information as may be required by ST Pay
      </p>
      <p>
        b) You must not provide any false, inaccurate, incomplete or misleading
        information. <br />
        c) You are responsible for ensuring that your registration details are
        maintained up to date. <br />
        d) ST Pay may reject your application at its discretion if any of the
        account opening requirements are not met or if you fail to provide us
        with satisfactory proof of identification. <br />
        e) ST Pay reserves the right to request further information pertaining to
        your account at any time failure to which may result in limitation on
        usage of the ST Pay Service, suspension or termination of the account.
      </p>

      <h4>7. Account Usage</h4>
      <p>
        a) After account verification and approval, you will be able to receive
        all the services provided by ST Pay. <br />
        b) ST Pay does not warrant that all functionalities shall be available at
        all times and may withdraw any functionality or ST Pay Service in general
        as a direct result of new or amended legislation, statutory instrument,
        Government regulations or policy or any other compelling reason. <br />
        c) ST Pay may provide additional functionality on the ST Pay menu
        following integration with platforms of financial institutions and other
        entities providing you with specified services. Such functionality may
        give you limited access to platforms of the financial institutions or
        other entities. By using such functionality to gain access to other
        platforms, you hereby agree to indemnify ST Pay against, and hold ST Pay
        harmless from any losses arising from your access to such external
        platforms.
      </p>
      <h4>8. Link Bank</h4>
      <p>
        a) To link your bank account with your ST Pay account, you are required
        to select your Bank from the bank list and insert your Bank Account
        information correctly. <br />
        b) During the process of link bank, you will be redirected to your
        bank’s Internet Banking page where you have to follow bank’s instruction
        or if your bank doesn’t have Internet banking an OTP will be sent to
        your bank registered mobile number for the purpose of authentication.
        You have to enter the OTP and the link will be established. <br />
        c) By linking your bank account to ST Pay account you hereby also
        authorize Green & Red Technologies Limited (ST Pay) to debit or credit
        your linked Bank Account through the settlement bank of ST Pay eWallet.{" "}
        <br />
        d) Once the link is established, you are able to add money from your
        linked bank account to your ST Pay account or you can transfer money from
        your ST Pay account to your linked bank account. <br />
        e) ST Pay shall not be held liable if your link bank request is failed
        due to incorrect bank or KYC information or mismatch with that of the
        Bank Account. In addition, ST Pay shall not be liable for any mishandling
        of transactions that occurs due to the fault of the bank or your device
        or internet issue.”. <br />
        f) ST Pay reserves the right to add, remove and modify the existing terms
        and conditions for this service at any time as necessary without giving
        any prior notice. <br />
        g) In case of non-delivery of OTP, pending Add Money or any other issue
        associated with your Bank account or service, you are required to
        contact your Bank. <br />
        h) You shall abide by any further burden or obligation if the bank or
        any other government authority provides any such obligation upon you{" "}
        <br />
        i) In case of any issue associated with ST Pay account, you are requested
        to contact ST Pay Customer Care Center by calling 00000000, by sending
        e-mail to support@stpay.net, by raising a ticket or by live chat from
        our application.
      </p>
      <h4>9. Services Given by Third Party Networks</h4>
      <p>
        a) In case of any transaction through a third-party network, the
        mentioned party will solely be liable for the accuracy and authenticity
        of the information related to their services given by them. ST Pay is a
        payment service provider only and in no way liable for the accuracy and
        authenticity of their services. If you want to return a purchase or want
        to refund it will be followed by the third party’s prescribed terms and
        conditions. <br />
        b) For Third Party service-related queries, please contact the party or
        their authorized representatives. ST Pay reserves the right to replace or
        amend its features, facilities and charges, and the information and
        materials pertaining to such features, facilities and charges, in
        relation to the services provided by ST Pay, its contents, website, or
        any of its products
      </p>

      <h4>10. Transactions through ST Pay</h4>
      <p>
        a) Your Transaction request through ST Pay may not be completed: <br />
        i. If you have insufficient funds in your ST Pay account to complete a
        Transaction or to cover the charges for a transaction; <br />
        ii. If you have reached the maximum account balance limit prescribed by
        Bangladesh Bank; <br />
        iii. If you have reached the daily limit prescribed by Bangladesh Bank;{" "}
        <br />
        iv. If your Account has been temporarily suspended or permanently
        frozen; <br />
        v. If there is any other compelling reason such as temporary system
        delay or outage. <br />
        vi. If your transaction seems suspicious to ST Pay for any reason
        whatsoever; at the discretion of ST Pay. <br />
        b) In case of Add Money transaction, we will be able to add money from
        banks available in ST Pay list or any VISA/Master/UnionPay debit or
        credit card. <br />
        c) You are required to enter correct OTP received from your issuer bank
        through your bank registered mobile number for the purpose of Adding
        Money to your ST Pay account. <br />
        d) Green & Red Technologies Limited (ST Pay) shall not be liable for
        any Add Money being rejected by the system for the
        mistake/non-compliance of rules by you. <br />
        e) In case of transfer money to your bank account or credit card bill
        payment, you will be able to transfer money to any bank account or any
        VISA/Master/UnionPay debit or credit card. <br />
        f) It may take up to 2 working days to complete the transfer money
        process to a bank account or credit card bill payment. <br />
        g) Green & Red Technologies Limited (ST Pay) shall not be liable for
        any delay by your Bank to credit your Bank account through Transfer
        Money. <br />
        h) You are required to enter correct OTP or your transaction PIN of
        ST Pay (if required) to complete a transaction. <br />
        i) It is your responsibility to ensure that the status of the concerned
        Bank/card Account is active (is not frozen, dormant, or others) and
        transactions are allowed in the concerned Bank Account. ST Pay shall not
        be liable for any unsuccessful transaction due to the status of the
        Bank/card account and neither does ST Pay have any responsibility to
        communicate with the bank to secure any transaction. <br />
        j) ST Pay may cancel or reverse any transaction or debit your ST Pay
        account to correct any mistake, overpayment, or for any other reasonable
        grounds after proper investigation. If such cancelation, reversal, or
        debit is made by ST Pay, you will be duly notified. <br />
        k) You are bound to bear the fees/charges (if any) imposed by ST Pay
        which may vary from time to time for the services rendered. <br />
        l) You must ensure confidentiality, custody, and protection of the PIN
        of your ST Pay Account and the OTP provided by bank and/or ST Pay. ST Pay
        shall not be held accountable for any consequences in case PIN, OTP or
        the ST Pay account is compromised by you. <br />
        m) In case of non-delivery of OTP, pending Add Money or any other issue
        associated with your Bank account or service, you are required to
        contact your Bank. <br />
        n) You must ensure confidentiality, custody, and protection of the PIN
        of your ST Pay Account and the OTP provided by bank and/or ST Pay. ST Pay
        shall not be held accountable for any consequences in case PIN, OTP or
        the ST Pay account is compromised by you. <br />
        o) A Transaction once completed is final and irrevocable. As such, ST Pay
        shall not be under any compulsion to reverse any transaction. Therefore,
        you should ensure that the correct bank/card/ST Pay account details are
        entered while performing the transaction. <br />
        p) ST Pay will not be compelled to refund or compensate the sender if the
        fund is sent to a recipient mistakenly or in error or fraudulently or
        under duress or otherwise spent by the recipient of the mistaken,
        erroneous or fraudulent transaction and you hereby agree to hold ST Pay
        harmless for any losses arising from a mistaken, erroneous or fraudulent
        transfer of funds or any transfer of funds from your account effected
        under duress or under coercion or criminal force. <br />
        q) In case of any issue associated with your Bank account or service,
        you are required to contact your Bank. <br />
        r) In case of any issue associated with ST Pay account, you are requested
        to contact ST Pay Customer Care by sending e-mail to designated email
        address, by raising a ticket or by live chat from our application.
      </p>

      <h4>11. Micro Merchant Account</h4>

      <p>
        a) All the terms and conditions of ST Pay business wallet are applicable
        for micro merchant accounts except limit, fees & charges and wallet hold
        limit. <br />
        b) Micro merchants will be boarded by the authorized
        representatives/promoters of ST Pay. <br />
        c) Monthly Transaction Limit for micro merchant account will be BDT
        10,00,000. <br />
        d) The Wallet Holding Limit at any point of time for a micro merchant
        account will be BDT 5,00,000. <br />
        e) If a personal account holder wants to have a business account s/he is
        eligible to convert his/her personal account into a micro merchant
        account by using the same NID number. However, the personal account
        holder will be required to satisfy any additional requirement that may
        be necessary to operate as a merchant account holder. <br />
        f) This conversion process will be for free of cost. However, if any
        government authorities require any additional payment for such
        conversion, you will be solely responsible to make such payments.”{" "}
        <br />
        g) The authorized representatives/promoters may ask for additional
        documents to board a micro merchant approved by local representatives
        (i.e- Chairman, ward commissioner, MP, any cooperative society that
        represents his/her profession) for professional verification.
      </p>

      <h4>12. Usage of Product</h4>

      <p>By accepting the terms and conditions, you:</p>

      <p>
        a) Accept that any valid transaction originating from your registered
        mobile number shall be assumed to have been initiated by you and any
        transaction authorized by the PIN/OTPs is duly and legally authorized by
        you. <br />
        b) Agree not to disclose your PIN, OTP to anyone including any officers
        of Green & Red Technologies Limited (ST Pay). <br />
        c) Agree that you are aware and accept that ST Pay e-wallet will enable
        you to transact using PIN/ OTPs within the limit prescribed by the
        Bangladesh Bank. <br />
        d) Authorize ST Pay for all transactions/services undertaken by using
        Password, transaction PIN, fingerprints and OTPs. <br />
        e) Agree to use the services offered under the Product using the PIN in
        accordance with the procedure as laid down by ST Pay from time to time,
        including the terms and conditions contained herein. <br />
        f) Agree to keep the PIN and OTPs confidential and will not disclose
        these to any other person or will not record them in a way that would
        compromise the confidentiality of the same or the security of the
        service. <br />
        g) Agree that the transactions originated using the mobile app or web
        app are non-retractable. <br />
        h) Understand and explicitly agree that ST Pay has the absolute right to
        revise the prescribed ceilings prescribed by Bangladesh Bank and charges
        from time to time which will be binding upon you. <br />
        i) Agree to use the product properly and validly registered with your
        number and undertake to use the wallet only through mobile phone number
        or via online which has been used to register for the e-wallet. <br />
        j) Expressly authorize ST Pay to carry out all requests/ transactions
        purporting to have been received from your mobile phone or online and
        authenticated with your PIN/OTPs. In case of payment facilities like add
        money, supplier payment, withdrawal etc., you shall be deemed to have
        expressly authorized ST Pay to make the payment when a request is
        received from your merchant account. <br />
        k) Agree to keep yourself updated with regard to any
        information/modification relating to the services offered which would be
        publicized on ST Pay’s websites or notification sent on the ST Pay app and
        would be responsible for taking note of/compliance of such information/
        modifications in making use of the Product
      </p>

      <h4>13. Fees and charges</h4>

      <p>
        a) Any payments made to you by us will be governed by the following
        terms: <br />
        i. The net payments will be made available by us to you after
        making the following adjustments, deductions and any other deductions in
        terms of these terms and conditions from the amount of the transaction:{" "}
        <br />
        a. the fees, charges, etc. due to us in terms of these terms
        and conditions as amended from time to time for all transactions
        processed.          b. all costs, charges, expenses, etc. of whatsoever
        nature on account of, inquiries, disputes, cancellations and/or refunds
        processed on account of your customer charges and/or chargeback. <br />
        c. any VAT/Tax applicable in any manner. <br />
        ii. We will reconcile the accounts and thereafter make payment to
        you, subject to our obligations to withhold payment of the amount
        arrived for any reason mandated under applicable laws, these terms and
        conditions or terms of any agreements entered into us and yourself.{" "}
        <br />
        iii. We will be entitled to set off and/or deduct from any payment
        due to you, such payments that are subsequently determined to be not due
        and/or wrongly paid to you. <br />
        iv. You agree that subject to applicable laws, we are authorized
        and entitled to debit your account for VAT, service Tax, bank charges,
        other applicable Taxes, <br />
        refund (s) to customers and any other amounts due and payable by you to
        us in terms of these terms and conditions in any other manner
        whatsoever. <br />
        b) All charges applicable to the ST Pay service are set forth in a
        separate fee schedule available at the website of ST Pay
        (www.stpay.com) and as may be published from time to time by Green
        & Red Technologies Limited (G&R) and are subject to change at any time
        at G&R’s sole discretion and your continued use of ST Pay services will
        be presumed that you are have accepted the new fees. <br />
        c) We reserve the right to seek indemnities from you for losses suffered
        by us under any heads of claims permissible under applicable laws or for
        any reason, such as: <br />
        i. Any transaction is for any reason unlawful or unenforceable;{" "}
        <br />
        ii. Any transaction made outside the territory authorized for the
        use of such instrument; <br />
        iii. Any transaction that is doubtful or erroneously paid for, to
        you; or <br />
        d) We reserve the right to discontinue the service on any of these
        grounds or for any other grounds that we consider necessary and recover
        such amounts from you and to be indemnified in relation to any losses in
        connection thereto.
      </p>

      <h4>14. Responsibilities and obligations of the account holder</h4>
      <p>
        a) You shall be responsible for all transactions, including
        erroneous/mistakenly done transactions initiated through ST Pay, mobile
        number and PIN; regardless of whether such transactions are authorized
        by you. You will also be responsible for the loss/damage, if any
        suffered in respect of all such transactions. <br />
        b) You shall take all possible steps to ensure that the PIN, Password
        and OTPs are not shared with any unauthorized person and shall contact
        ST Pay customer care center in case of misuse/ theft/loss of the mobile
        phone. <br />
        c) It will be your responsibility to notify ST Pay immediately if you
        suspect the misuse of the PIN/Password. <br />
        d) You shall be liable for all loss or breach of the terms and
        conditions contained herein or contributed or caused the loss by
        negligent actions or a failure to advise ST Pay within a reasonable time
        about any unauthorized access in the Wallet.
      </p>

      <h4>15. Others</h4>

      <p>
        {" "}
        a) Your instructions will be affected only after authentication under
        your Registered number and PIN or through any other mode of verification
        as may be stipulated at the discretion of ST Pay. <br />
        b) ST Pay shall make all reasonable efforts to ensure that your
        information is kept confidential. <br />
        c) You will be required to acquaint yourself with the process for using
        the Product and that you shall be responsible for any error made while
        using the Product. <br />
        d) ST Pay reserves the right to decide what services may be offered.
        Additions/ deletions to the services offered under the product are at
        its sole discretion. <br />
        e) While it shall be the endeavor of ST Pay to carry out the instructions
        received from you promptly, it shall not be responsible for the delay/
        failure in carrying out the instructions due to any reasons whatsoever
        including failure of operational system or due to any requirement of
        law. You expressly authorize ST Pay to access your e-wallet information
        required for offering features under the service and also to share the
        information regarding your e-Wallet with the service provider/ third
        party as may be required to provide the services. <br />
        f) The transactional details will be recorded by ST Pay and these records
        will be regarded as conclusive proof of the authenticity and accuracy of
        transactions. <br />
        g) You hereby authorize ST Pay or its representatives to send promotional
        messages including the products, greetings, advertising or any other
        messages ST Pay may consider from time to time. <br />
        h) You understand that ST Pay may send "rejection" or "cannot process the
        request" messages for the service requested by you which could not be
        executed for any reason. <br />
        i) ST Pay reserves the right to replace or amend its features, facilities
        and charges, and the information and materials pertaining to such
        features, facilities and charges, in relation to the services provided
        by ST Pay, its contents, website, or any of its products
      </p>

      <h4>16. User Information</h4>

      <p>
        a) You agree that you shall defend, indemnify and hold ST Pay harmless
        from and against any and all claims, costs, damage, losses, liabilities
        and expenses arising out of or in connection with your violation or
        breach of any of these terms and conditions or any applicable law or
        regulation, or your use or misuse of the services of ST Pay. <br />
        b) You shall be liable for any claim for damages, penalty, losses, legal
        suit, or criminal offence if such an occurrence arises due to any
        unauthorized use of the services of ST Pay. <br />
        c) It is your responsibility to provide correct information to ST Pay
        through the use of the Product or any other method. In case of any
        discrepancy in this information, you understand that ST Pay will not be
        in any way responsible for action taken based on the information. ST Pay
        will endeavor to correct the error promptly wherever possible on a best
        effort basis, if you report such error within 2 (two) hours of providing
        the error in information. <br />
        d) If you receive information about another ST Pay customer, you must
        keep the information confidential and only use it in connection with the
        ST Pay services. You may not disclose or distribute any information about
        ST Pay users to a third party or use the information for marketing
        purposes unless you receive that user’s express consent to do so. You
        may not send unsolicited emails to a ST Pay customer or use the ST Pay
        services to collect payments for sending, or assist in sending,
        unsolicited emails to third parties. <br />
        e) We are not responsible for any loss or damage you suffered as a
        result of you providing wrong information. <br />
        f) In case of providing inaccurate or incorrect information to us, we
        may immediately limit or suspend or close your account and refuse to
        provide our Services to you. g) In case of any authorized regulatory
        body’s involvement or order, we are bound to provide information to them
        for any kind of investigation or may even freeze your account until such
        investigation is resolved. <br />
        h) ST Pay reserve the right to refuse any service to any ST Pay user
        without giving any reason or prior warning.
      </p>

      <h4>17. Refund, Chargeback and VOID</h4>
      <p>
        a) Refund/Chargeback request should be initiated within 15 days of
        settlement of the transaction. <br />
        b) Chargeback will be claimed by the wallet account holder through
        raising a ticket from app or web portal. Each chargeback is accompanied
        by a reason. These reasons offer an explanation as to why the consumer
        is disputing the transaction. Each reason has its own set of rules
        (filing time limits, necessary documentation, etc.). ST Pay will check
        the customer’s chargeback claim, making sure all the regulations have
        been addressed. <br />
        c) If the customer has a valid claim, the funds will be removed from
        your wallet account and credited to the customer’s account. If the
        account holder doesn’t have a valid claim, the chargeback will be
        voided. <br />
        d) If the chargeback is justified, you should accept the losses.
        However, if the business has sufficient compelling evidence
        (documentation to prove the chargeback is invalid), the merchant can
        represent the chargeback for review. If a chargeback process is
        successful, it will auto reconcile customers, ST Pay and Merchant.
        Merchant will be liable to pay the fees associated with the disputed
        transaction. <br />
        e) If a customer wants to return a purchase or wants a refund for
        non-delivery items it will be followed by the merchant’s prescribed
        terms and conditions. If you find the request valid as per your business
        policy, you can initiate the refund through the application. Merchant
        will be liable to pay the fees associated with the transaction amount.{" "}
        <br />
        f) The merchant application (Android/ iOS) will provide you the access
        to initiate a Refund or a VOID transaction. The merchant shall be solely
        responsible for any defect or problems associated with the application.{" "}
        <br />
        g) Through ST Pay's Settlement' feature, you will be able to VOID or
        cancel any transaction done by mistakenly after it has been authorized
        but before it has been settled. <br />
        h) If we receive a request for Refund/chargeback of payment, the dispute
        resolution process will be followed and it will vary case to case.
      </p>

      <h4>18. Disclaimer</h4>
      <p>
        a) ST Pay, when acting in good faith, shall be absolved of any liability
        in case: <br />
        i. ST Pay is unable to receive or execute any of the requests from you
        when there is loss of information during processing or transmission or
        any unauthorized access by any other person or breach of confidentiality
        or due to reasons beyond the control of the ST Pay. <br />
        ii. There is any kind of loss, direct or indirect, incurred by you or
        any other person due to any failure or lapse in the product which are
        beyond the control of the ST Pay. <br />
        iii. There is any failure or delay in transmitting of information or
        there is any error or inaccuracy of information or any other consequence
        arising from any cause beyond the control of Cashaba which may include
        technology failure, mechanical breakdown, power disruption, etc. <br />
        iv. There is any lapse or failure on the part of the service providers
        or any third party affecting the services and that ST Pay makes no
        warranty as to the quality of the service provided by any such provider
        for any delay or non-performance of its obligations under these terms
        and conditions arising from any cause or causes beyond its reasonable
        control, including (but not limited to) an act of God, governmental act,
        earthquake, war, fire, flood, explosion, civil commotion or industrial
        dispute whether lawful or otherwise, armed hostilities, act of
        terrorism, suicide bombing and attacks, explosions; revolution;
        blockade; embargo; strike; lock-out sit-in industrial or trade dispute;
        adverse weather; epidemic or pandemic; industrial accident and natural
        disasters. <br />
        b) ST Pay and its employees or promoters shall not be liable for and in
        respect of any loss or damage whether direct, indirect or consequential,
        including but not limited to loss of revenue, profit, business,
        contracts, anticipated savings or goodwill, loss of use or value of any
        equipment including software, whether foreseeable or not, suffered by
        you or any person howsoever arising from or relating to any delay,
        interruption, suspension, resolution or error of ST Pay in receiving and
        processing the request and in formulating and returning responses or any
        failure, delay, interruption, suspension, restriction, or error in
        transmission of any information or message to and from the
        telecommunication equipment of ST Pay Users and the network of any
        service provider and ST Pay’s system or any breakdown, interruption,
        suspension or failure of the telecommunication equipment of ST Pay Users,
        ST Pay’s system or the network of any service provider and/or any third
        party who provides such services as is necessary to provide the Product.{" "}
        <br />
        c) ST Pay will not be responsible if the e-wallet application is not
        compatible with/ does not work on your mobile handset. <br />
        d) ST Pay will not be liable for any loss caused by a technical breakdown
        of the payment system. <br />
        e) Use of the e-wallet shall be terminated without notice at ST Pay’s
        discretion which may be upon the death, bankruptcy or insolvency of the
        account holder or on receipt of request from the e-wallet holder,
        receipt of an attachment order from a competent court or revenue
        authority, due to violation of ST Pay regulations, or for other valid
        reasons or when the whereabouts of the e-wallet holder become unknown to
        ST Pay due to any cause attributable to the e-wallet holder or any other
        reason which ST Pay deems fit.
      </p>

      <h4>19. Right to use logos</h4>
      <p>
        You may be permitted to prominently display, on your official website
        and in other online marketing materials if applicable, a
        statement/logo/trademark/image provided by ST Pay. This
        statement/logo/image may be prominently displayed to all Users as
        notified by ST Pay from time to time. You acknowledge that ST Pay is the
        sole and exclusive owner of its trademarks, service marks, logos and/or
        any other marks, and agree that you will not contest the ownership of
        the said marks for any reason whatsoever. You also accept that ST Pay may
        at any time, immediately and without advance notice prohibit you from
        using any of the marks for any reason.
      </p>
      <h4>20. Prohibited actions and Transactions</h4>
      <p>The following transactions or actions are prohibited:</p>
      <p>
        a) Using your account for purposes including, but not limited to, Money
        Laundering, fraud, terrorist financing or such other illegal/criminal
        activities.
        <br />
        b) Refusing to cooperate in an investigation or provide confirmation of
        your identity or any other information provide by you to ST Pay;
        <br />
        c) Providing false, inaccurate or misleading information;
        <br />
        d) Offering any Banned or illegal Goods/Services banned by the law of
        the land for sale to users
        <br />
        e) If you act in breach or ST Pay believes that you may be acting in
        breach of the prohibitions, ST Pay reserve the right to:
        <br />
        i. Decline to perform or reverse the transaction and simultaneously
        limit or terminate or suspend your account;
        <br />
        ii. Report the transaction to the relevant law enforcement agency
        <br />
        iii. It is strictly your responsibility to ensure that you only
        send or receive e-money from or to Persons or entities for the sale or
        supply of goods and services that you may provide or receive in
        compliance with any applicable laws and regulations. It is required that
        you should not proceed with any transactions in the instance where you
        have any hints that the transaction is not legal.
      </p>
      <h4>21. Responsibility for Security as Per PCI DSS</h4>
      <p>
        a) ST Pay has established security procedures and shall make reasonable
        efforts consistent with industry standards to protect cardholder data,
        meet all applicable audit requirements and comply with PCI DSS
        (hereinafter “Payment Card Industry Data Security Standards”) and such
        other applicable rules, regulations, codes of practice, guidance and
        industry standards related to the handling and processing of credit card
        data in force from time to time during the term hereof “Payment Card
        Issuer Requirements”, as established by the PCI Security Standards
        Council. <br />
        b) ST Pay is responsible for the security of cardholder data ST Pay
        possesses or otherwise stores, processes, or transmits on Customer’s
        behalf, or to the extent ST Pay could impact the security of Customer’s
        cardholder data environment. <br />
        c) ST Pay agrees to comply with all applicable PCI DSS requirements to
        the extent that ST Pay handles, has access to, or otherwise stores,
        processes or transmits Customer’s cardholder data, or manages Customer’s
        cardholder data environment. <br />
        d) ST Pay agrees to comply with all applicable laws that require
        notification of individuals or parties in the event of unauthorized
        disclosure of cardholder data. Pursuant to the provisions hereof, in the
        event of a breach of any of ST Pay’s security obligations relating to PCI
        or other event requiring notification under applicable law, ST Pay agrees
        to assume responsibility for informing all such individuals in
        accordance with applicable laws, and, subject to the indemnity and
        limitations on liability provisions contained herein. As a merchant or a
        service provider of ST Pay you must comply with all applicable PCI DSS
        requirements to the extent that you handle, have access to, or otherwise
        store, process or transmit Customer’s cardholder data, or manage
        Customer’s cardholder data environment.
      </p>
      <h4>22. Governing Law & Jurisdictions</h4>
      <p>
        The Product and the terms and conditions of the same are governed by the
        applicable laws in the Peoples’ Republic of Bangladesh such as-
        Bangladesh Bank regulations under PSP license, Payment and settlement
        act, 2014 or any other regulatory bodies.
      </p>

      <h4>23. Indemnity</h4>
      <p>
        In consideration of ST Pay providing the Product, you agree to indemnify
        and hold ST Pay harmless against all actions, claims, demands
        proceedings, loss, damages, costs, charges and expenses which ST Pay may
        at any time incur, sustain, suffer or be put to as a consequence of or
        arising out of or in connection with any services provided to you
        pursuant hereto. You shall indemnify and keep indemnified ST Pay for
        unauthorized access by any third party to any
        information/instructions/triggers given by the you or breach of
        confidentiality.
      </p>

      <h4>24. Modification of this Terms and Conditions</h4>
      <p>The following transactions or actions are prohibited:</p>
      <p>

        a) Any notice or notification in connection with these terms and conditions will be communicated through your registered email or via ST Pay Merchant App or via ST Pay official website. <br />
        b) Please ensure that you read the revised terms and conditions carefully because you will be bound by the revised terms and conditions from this date. <br />
        c) ST Pay reserves the right to change, modify, add, or remove portions of this terms and conditions (each, a change and collectively, changes) at any time by posting notification on the ST Pay Platform or otherwise communicating the notification to you. The changes will become effective, and shall be deemed accepted by you, 24 hours after the initial posting and shall apply immediately on a going-forward basis with respect to your use of the ST Pay Platform, availing the ST Pay Services or for payment transactions initiated after the posting date. If you do not agree with any such change, your sole and exclusive remedy is to terminate your use of the ST Pay Services/ST Pay Platform. For certain changes, ST Pay may be required under applicable law to give you advance notice, and ST Pay will comply with such requirements. Your continued use of the ST Pay Platform following the posting of changes will mean that you accept and agree to the changes.
      </p>

      <h4>25. Severability</h4>
      <p>
        If any provision of this terms and condition is held to be unlawful,
        void, invalid or otherwise unenforceable, then that provision will be
        limited or eliminated from this terms and conditions to the minimum
        extent required, and the remaining provisions will remain valid and
        enforceable.
      </p>

      <h4>Annexure A</h4>
      <table className="myTable">
        <tbody>
          <tr>
            <th colSpan="3" className={classes.center}>
              Transaction Limit for Merchant Wallet
            </th>
          </tr>
          <tr>
            <td>Sl. No</td>
            <td>Description</td>
            <td>Amount (BDT)</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Maximum balance at any point in time</td>
            <td>As per Transaction Profile</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Add Money through any channel per transaction</td>
            <td>As per Transaction Profile</td>
          </tr>

          <tr>
            <td>3</td>
            <td>Sale</td>
            <td>As per Transaction Profile</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Vendor Payment</td>
            <td>As per Transaction Profile</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Transfer to Bank A/C</td>
            <td>As per Transaction Profile</td>
          </tr>
        </tbody>
      </table>
      <p>
        Note: * ST Pay reserves the right to change the limit structure at its
        discretion.
      </p>
    </div>
  );
};

export default MerchantTC;
